<template lang="pug">
  v-main(v-if='Blog' fluid)
    //-PC
    div.d-none.d-md-flex
      v-img( gradient="to top right, rgba(180, 50, 36, 0.45) , transparent" :src='Blog.portada' aspect-ratio='3' )
        v-row.mt-12
        v-row.mt-12    
        v-row.mt-12    
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12.ml-12.mb-12 
          div.rectangulo_titulo.secundario.mr-4.mt-4
          h1(v-html='Blog.título').white--text 
        //- v-divider.primario.divider_inicial
    //-Móvil
    div.d-md-none
      v-img( gradient="to top right, rgba(0, 0, 0,.8) ,rgba(69, 64, 64 ,.28)" :src='Blog.portada' aspect-ratio=1.9)
        v-row.align-center.justify-center        
          v-col(md=11)
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.align-start.justify-start.mt-5.ml-4 
                div.rectangulo_titulo_movil.primario.mt-4.mr-2
                h2.white--text.mt-4 BLOG
    v-container(v-if='!$route.params.slug' fluid).pt-1
      Novedades_completas_container
    v-container(v-if='$route.params.slug' fluid).pa-0.ma-0
      router-view
</template>          
<script>
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    Novedad_item: ()=>import('./componentes/novedades/novedad_item'),
    Novedades_completas_container: ()=>import('./componentes/novedades/novedades_completas_container'),
    Novedades_por_etiqueta_container: ()=>import('./componentes/novedades/novedades_por_etiqueta_container'),
  },
  computed: {
    ...mapState({
      Etiqueta_filtro: ({Etiquetas})=>Etiquetas.etiqueta_filtro,
    }),
  },
}
</script>
<style lang="sass">
  .rectangulo_titulo
    width: 14px
    height: 73px
  .rectangulo_titulo_movil
    width: 10px
    height: 40px
</style>
<style lang="sass">
  .divider_inicial
    max-height: 10px!important
    height: 9px!important
</style>